import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginLr3Component} from "./login-lr3/login-lr3.component";

const routes: Routes = [
  {
    path: "login",
    children: [
      {
        path: "autopets",
        children: [
          {
            path: "lr3",
            component: LoginLr3Component
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OauthRoutingModule { }
