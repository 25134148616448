import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  log(message) {
    console.log(message);
  }

  error(error) {
    console.log(error.message);
  }

}
