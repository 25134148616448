import { Injectable } from '@angular/core';
import {environment} from "src/environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

export let HttpMethod = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
  PATCH: "PATCH"
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseApiUrl: string = environment.baseApiUrl;
  httpOptions: any = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.apiKey
    }),
    responseType: 'json'
  };

  constructor(private http: HttpClient) {
  }

  /**
   * Log into existing litter robot magento credentials
   * @param username
   * @param password
   */
  autopetsLitterRobotLogin(username: string, password: string): Observable<any> {
    let url = `${environment.autopetsLr3BaseApi}/login`;
    this.httpOptions.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.autopetsLr3ApiKey
    });
    return this._request(HttpMethod.POST, url, {
      email: username,
      password: password
    }, this.httpOptions);
  }

  postAuthorizationRequestLr3(token, clientId, scope, state, redirectUri) {
    this.httpOptions.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    });
    let url = `${environment.baseApiUrl}/oauth/authorize/lr3?client_id=${clientId}&scope=${scope}&response_type=code&state=${state}&redirect_uri=${redirectUri}`;
    return this._request(HttpMethod.POST, url, null, this.httpOptions);
  }

  _request(method: string, url: string, body: any = null, options: any = null) {
    switch (method.toUpperCase()) {
      case "GET": {
        return this.http.get(url, options || this.httpOptions);
      }
      case "POST": {
        return this.http.post(url, body, options || this.httpOptions);
      }
      case "DELETE": {
        let deleteOptions = options || this.httpOptions;
        if (body) {
          deleteOptions["body"] = body;
        }
        return this.http.delete(url, deleteOptions);
      }
      case "PATCH": {
        return this.http.patch(url, body, options || this.httpOptions);
      }
    }
  }

}
