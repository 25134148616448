import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.sass']
})
export class ErrorComponent implements OnInit {

  defaultErrorMessage: string = "An Unknown Error has Occurred";
  errorMessage: string = "An Unknown Error has Occurred";

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(filter(params => params.message))
      .subscribe(params => {
        this.errorMessage = params.message || this.defaultErrorMessage;
      })
  }

}
