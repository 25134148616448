import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {LoggerService} from "../../service/logger.service";
import {ILr3User} from "../../service/lr3.service";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
/**
 * Create a new subclass of this component, see login-lr3 example
 */
export class LoginComponent implements OnInit {

  private pathParamsSubscription: Subscription;

  user: ILr3User = null;
  clientName: string;
  clientLogoUrl: string;
  queryParams: any;

  resourceName: string;
  resourceLogoUrl: string;

  signInMessage = "Please sign in to your Litter-Robot account";
  allowAccessMessage = "";

  username = new FormControl();
  password = new FormControl();

  loginForm: FormGroup = new FormGroup({
    username: this.username,
    password: this.password
  });

  error: string;

  constructor(
    public logger: LoggerService,
    public route: Router,
    public router: ActivatedRoute,
    public spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.pathParamsSubscription = this.router.queryParams
      .subscribe(params => {
        this.didUpdateQueryParams(params);
      }, err => {
        this.logger.error(err);
      })
  }

  didUpdateQueryParams(queryParams) {
    this.queryParams = queryParams;
    this.resourceLogoUrl = queryParams["resource_logo_url"];
    this.resourceName = queryParams["resource_name"];
    this.clientLogoUrl = queryParams["client_logo_url"];
    this.clientName = queryParams.client_name;
    this.allowAccessMessage = `${this.clientName} would like to gain access to your ${this.resourceName}'s data?`;
    this.signInMessage = `Please sign-in to your ${this.resourceName}'s account`;
  }

  onSubmit() {
    this.error = null;
    // To be implemented in your subclass
  }


  didClickAcceptAction() {
    // To be implemented in your subclass
  }

  didClickDenyAction() {
    // To be implemented in your subclass
  }

}
