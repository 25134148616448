import {Component} from '@angular/core';
import {LoginComponent} from "../login/login.component";
import {LoggerService} from "../../service/logger.service";
import {ILr3User, Lr3Service} from "../../service/lr3.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'app-login-lr3',
  templateUrl: '../login/login.component.html',
  styleUrls: ['../login/login.component.sass']
})
export class LoginLr3Component extends LoginComponent {

  user: ILr3User = null;
  private token: string;

  constructor(
    logger: LoggerService,
    route: Router,
    router: ActivatedRoute,
    spinner: NgxSpinnerService,
    private lr3: Lr3Service) {
    super(logger, route, router, spinner)
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onSubmit() {
    super.onSubmit();
    this.spinner.show();
    this.logger.log(this.username.value);
    this.lr3
      .login(this.username.value, this.password.value)
      .subscribe(result => {
        this.user = result.user;
        this.token = result.token;
        this.spinner.hide();
      }, err => {
        this.loginError(err);
        this.spinner.hide();
      })
  }

  private loginError(err: HttpErrorResponse) {
    this.logger.error(err);
    switch (err.status) {
      case 401:
        this.error = "Invalid username or password";
        break;
      default:
        this.error = "Unable to authenticate at this time";
        break;
    }
  }

  didUpdateQueryParams(queryParams) {
    super.didUpdateQueryParams(queryParams);
    this.logger.log(queryParams)
  }

  didClickAcceptAction() {
    this.spinner.show();
    let {client_id, scope, state, redirect_uri} = this.queryParams;

    /**
     If the user chooses to accept, send a post request to request for an oauth token.  You'll receive a redirectUri from
     the response and the user should be re-directed to the url.
     */
    this.lr3
      .requestAuthorizationCode(this.token, client_id, scope, state, redirect_uri)
      .subscribe(result => {
        this.spinner.hide();
        // Redirect user to the url
        window.location.href = result["redirectUri"];
      }, err => {
        this.logger.error(err);
        this.spinner.hide();
      })
  }

  didClickDenyAction() {
    let {redirect_uri} = this.queryParams;
    window.location.href = `${redirect_uri}?error=access_denied`;
  }

}
