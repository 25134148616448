import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OauthRoutingModule} from './oauth-routing.module';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoginLr3Component} from './login-lr3/login-lr3.component';
import {NgxSpinnerModule} from "ngx-spinner";

@NgModule({
  declarations: [
    LoginComponent,
    LoginLr3Component
  ],
  imports: [
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    OauthRoutingModule
  ]
})
export class OauthModule {
}
