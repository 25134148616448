import {Injectable} from '@angular/core';
import {ApiService, HttpMethod} from "./api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface ILr3User {
  lastName: string,
  firstName: string,
  userEmail: string
}

export interface Lr3LoginResponse {
  user: ILr3User,
  token: string
}

@Injectable({
  providedIn: 'root'
})
export class Lr3Service {

  constructor(private apiService: ApiService) {
  }

  public login(email: string, password: string): Observable<Lr3LoginResponse> {
    return this.apiService
      .autopetsLitterRobotLogin(email, password)
      .pipe(map(result => {
        let {identityId, user, token} = result;
        return {
          identityId,
          user,
          token,
        } as Lr3LoginResponse
      }))
  }

  public requestAuthorizationCode(token, clientId, scope, state, redirectUri): Observable<any> {
    return this.apiService
      .postAuthorizationRequestLr3(token, clientId, scope, state, redirectUri)
      .pipe(map(result => result["data"]))
  }

}
